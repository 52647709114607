.each-slide-effect > div {
display: flex;
align-items: center;
justify-content: center;
background-size: cover;
height: 350px;
}
  
.each-slide-effect span {
    padding: 20px;
    font-size: 20px;
    background: #9c9b9b;
    text-align: center;
}